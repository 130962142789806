<template>
    <div id="wrapper" class="bg-white pt-4">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-8 offset-xl-2 col-lg-8 col-md-8 offset-lg-2 offset-md-2">

                    <div class="d-flex">
                        <div class="text-left p-4 pb-2">
                            <h3 class="text-uppercase text-danger fw-bold mb-1 mt-2" style="letter-spacing: 1px">Delete User</h3>
                            
                            <p style="font-size: 1.1rem">Please note that this action is <span class="text-danger fw-bold">irreversible</span> and once you delete your account you cannot get it back. Enter your email and password to confirm that it's you.</p>                                
                        </div>
                    </div>

                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12">
                                <status v-model:status_data=status_data></status>
                            </div>
                        </div>
                    </div>

                    <form id="form" action="#" @submit.prevent="deleteAccount()">
                        <div class="border-bottom-0 p-4 pt-2">
                            <div class="form-row">
                                <label class="mb-2 fw-bold" for="email">Email</label>
                                <div class="input-group">
                                    <span class="input-group-text border-right-0" @click="seePassword()">
                                        <font-awesome-icon id="eye" :icon="['fa', 'envelope']" />
                                    </span>

                                    <input id="email" type="email" class="form-control no-border" v-model="data.email" placeholder="Enter your Email" required>
                                </div>
                            </div>

                            <div class="row mt-4" id="password-row">
                                <label class="mb-2 fw-bold" for="password">Password</label>
                                <div class="input-group">
                                    <span class="input-group-text">
                                        <font-awesome-icon id="lock" :icon="['fa', 'lock']" />
                                    </span>

                                    <input id="password" type="password" class="form-control" v-model="data.password" placeholder="Password" autocomplete="new-password" required>

                                    <span class="input-group-text pe-4 ps-4" style="cursor: pointer" @click="seePassword()">
                                        <font-awesome-icon id="eye" :icon="['fa', eye]" />
                                    </span>
                                </div>
                            </div>

                            <div class="row mt-2" id="login">
                                <div class="col">
                                    <button class="btn bg-danger text-white btn-sm mt-4 w-100 text-uppercase fw-bold pt-2 pb-2" type="submit">
                                        <span style="letter-spacing: 2px"> Delete User </span> <font-awesome-icon class="icon ms-1 me-1" :icon="['fa', 'trash']" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>  
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    @import '../assets/scss/base/_base.scss';
    @import '../assets/scss/base/_fonts.scss';
    @import '../assets/scss/helpers/breakpoints.scss';

    .card {
        box-shadow: 0 4px 8px rgb(0 0 0 / 3%)
    }

    .input-group {
        .input-group-text {
            background: #eee;
        }

        .form-control {
            background: #eee;
        }
    }  
</style>

<script>
    import Status from '../components/Status';

    export default {
        name: 'DeleteUser',
        components: {
            Status,
        },
        data() {
            return {
                data: {
                    email: '',
                    password: '',
                },
                status_data: {
                    "success": false,
                    "success_message": null, 
                    "error": false,
                    "error_message": null
                },
                errors: [],
                eye: 'eye',
                loading: false
            }
        },
        methods: {
            seePassword() {
                if(document.querySelector('#password').type == 'password') {
                    document.querySelector('#password').type = 'text'
                    this.eye = 'eye-slash'
                } else {
                    document.querySelector('#password').type = 'password'
                    this.eye = 'eye'
                }
            },
            deleteAccount() {
                this.status_data.loading = true

                this.axios.post('api/users/deactivate', this.data).then(() => {
                  this.status_data.loading = false
                  this.status_data.success = true
                  this.status_data.error = false
                  this.status_data.success_message = 'Account Deleted!'

                  localStorage.removeItem('user')

                  setTimeout(() => {
                    this.$router.push({name: 'Login'});
                  }, 1500);
                  
                }).catch(errors => {
                  this.status_data.loading = false
                  this.status_data.success = false
                  this.status_data.error = true
                  this.status_data.error_message = errors.message

                  console.log(errors)
                })
            }            
        }
    }
</script>